<template>
  <div>
    <v-snackbar v-model="snackbarToggle" timeout="1200" rounded>
      You can't set a reminder for a closed conversation
    </v-snackbar>
    <v-dialog v-if="convo && convo.convo_id" v-model="reminderDialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span v-if="!hasReminder" class="text-h6">Set Conversation Reminder</span>
          <span v-else class="text-h6">Edit Conversation Reminder</span>
          <v-spacer></v-spacer>
          <span class="text-h6">{{ convo.convo_name }}</span>
        </v-card-title>
        <v-card-text class="column justify-center m10">
          <v-list-subheader class="justify-center">Select a date and time</v-list-subheader>
          <div class="justify-center" style="text-align: center">
            <input style="width: 200px" type="datetime-local" v-model="reminderDate" :min="minReminderTime">
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="hasReminder" color="red darken-1" text @click="_deleteReminder()">
            Remove Reminder
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="_addReminder()">
            <div v-if="!hasReminder">Set new Reminder</div>
            <div v-else>Change Current Reminder</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConvoSerice from "@/services/convoService";
import colorMixin from "@/mixins/colorMixin";
import { mapGetters } from "vuex";
import eventBus from "@/eventBus";

export default {
  name: "ReminderPanel",
  mixins: [colorMixin],
  props: {
    // used to activate the reminder dialog
    toggleDialog: { type: Boolean, default: false },
    // the convo for the reminder
    convo: { type: Object, default: null },
  },
  data() {
    return {
      reminderDialog: false,
      snackbarToggle: false,
      reminderDate: this.moment().add(1, "minute").format("YYYY-MM-DDThh:mm"),
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    minReminderTime() {
      return this.moment().add(1, "minute").format("YYYY-MM-DDThh:mm");
    },
    // true if there is already a reminder set for this convo for the current user
    hasReminder() {
      return (
        this.convo &&
        this.convo.convo_reminders &&
        this.convo.convo_reminders.some((r) => r.reminder_for_who === this.userId)
      );
    },
  },
  watch: {
    toggleDialog() {
      if (!this.convo || !this.convo.convo_id) this.snackbarToggle = !this.snackbarToggle;
      this.reminderDialog = !this.reminderDialog;

      // if a reminder already exists, set the date and time to reflect the existing reminder
      if (this.hasReminder) {
        let r = this.convo.convo_reminders.find((r) => r.reminder_for_who === this.userId);
        this.reminderDate = this.minReminderTime;
      }
    },
  },
  methods: {
    _addReminder() {
      let date = this.moment(this.reminderDate).toISOString();
      console.log(date)

      // if we need to create a new reminder
      if (!this.hasReminder) {
        ConvoSerice.addReminder(this.convo.convo_id, date)
          .then(() => {
            this.reminderDialog = false;
          })
          .catch((e) => {
            console.log(e);
            eventBus.$emit("alert", "Error adding reminder");
          });
      }
      // if we need to modify the existing reminder
      else {
        ConvoSerice.updateReminder(this.convo.convo_id, date)
          .then(() => {
            this.reminderDialog = false;
          })
          .catch((e) => {
            eventBus.$emit("alert", "Error updating reminder");
            console.log(e);
          });
      }
    },
    _deleteReminder() {
      ConvoSerice.deleteReminder(this.convo.convo_id)
        .then(() => {
          this.reminderDialog = false;
        })
        .catch((e) => {
          eventBus.$emit("alert", "Error deleting reminder");
          console.log(e);
        });
    },
  },
};
</script>