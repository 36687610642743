<template>
  <div style="display: flex; flex-direction: column; height:100%;">
    <ReminderPanel :toggleDialog="toggleReminder" :convo="convo" />
    <!-- This row holds the messenger header -->
    <div style="flex: 1 1;">
      <MessengerHeader
        :header="header"
        :convo="convo"
        @updateConvoStatus="_updateConvoStatus($event)"
        @leaveConversation="leaveConversation()"
      />
    </div>
    <template v-if="loading && convo">
      <div style="flex: 10 10; position: relative;">
        <v-progress-linear indeterminate></v-progress-linear>
      </div>
    </template>
    <template v-else>
      <!-- This row holds the message chat virtual scroller -->
      <div style="flex: 10 10; position: relative;">
        <div v-if="!convo" style="font-weight: bold;">Enter a message to open the conversation</div>
        <div v-if="messages" style="position: absolute; top:0; left:0; bottom:0; right:0;">
          <DynamicScroller
            :items="messages"
            :minItemSize="25"
            key-field="convo_message_id"
            style="height: 100%;"
            :buffer="20000"
            ref="scroller"
          >
            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem :item="item" :active="active">
                <Message
                  :message="item"
                  :prev="index === 0 ? null : messages[index - 1]"
                  :index="index"
                  :active="active"
                />
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </div>
      </div>
    </template>
    <!-- this row holds the message box -->
    <div style="flex: 1 1; position: relative;">
      <v-overlay absolute :value="convo && !convo.convo_status" :opacity="0.08"></v-overlay>
      <MessengerBox
        :convo="convo"
        @messageSent="messageSent($event)"
        @reminderClicked="toggleReminder = !toggleReminder"
      />
    </div>
  </div>
</template>

<script>
import Message from "@/components/Messenger/Message";
import MessengerHeader from "@/components/Messenger/MessengerHeader";
import MessengerBox from "@/components/Messenger/MessengerBox";
import ReminderPanel from "@/components/Messenger/ReminderPanel";
import ConvoService from "@/services/convoService";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
import eventBus from "@/eventBus";

export default {
  name: "Messenger",
  components: {
    Message,
    MessengerHeader,
    MessengerBox,
    ReminderPanel,
  },
  props: {
    convo: { type: Object, default: null },
    system_object: { type: Object, default: null },
  },
  data() {
    return {
      loading: true,
      // contains all the messages for the convo
      // messages is a reference to the messages stored in moduleConvos throug vuex, so if the state changes,
      // it will be reflected here
      messages: null,
      toggleReminder: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    system_process: function() {
      if (this.convo) return this.convo.system_process;
      if (this.system_object.DSSClaimsID) return "outstanding claim";
      if (this.system_object.DSSRecallID) return "recall";
      return null;
    },
    system_process_id: function() {
      if (!this.system_object) return null;
      return this.system_object.DSSClaimsID || this.system_object.DSSRecallID;
    },
    header: function() {
      if (this.convo) return this.convo.convo_name;
      if (this.system_process === "outstanding claim") return this.system_object.PatName;
      if (this.system_process === "recall") return this.system_object.FName + " " + this.system_object.LName;
      return "error computing message header";
    },
  },
  watch: {
    convo: function() {
      this.loading = true;
      this.getConvoMessages();
    },
    "messages.length": function() {
      if (this.convo) {
        this.scrollToBottom();
        this.markAsRead(this.convo.convo_id);
      }
    },
  },
  created() {
    // get the messages once at creation and then every time convo changes
    this.getConvoMessages();
  },
  methods: {
    ...mapActions("convos", ["getMessages", "markAsRead"]),
    // get the messages for this convo
    getConvoMessages() {
      if (!this.convo) {
        this.messages = null;
        return;
      }

      this.getMessages(this.convo.convo_id)
        .then((messages) => {
          this.messages = messages;
          this.loading = false;
          this.scrollToBottom();
        })
        .catch((e) => console.log(e));
    },
    // adds a nessage to the convo
    messageSent(message) {
      // if this is the first message, we must create a conversation too
      if (!this.convo) {
        var c = {
          message,
          system_process: this.system_process,
          secured: 0,
          convo_name: this.header,
          system_process_id: this.system_process_id,
        };

        ConvoService.addConvo(c).catch((e) => {
          eventBus.$emit("alert", "Error adding conversation");
          console.log(e);
        });
      }
      // otherwise, we can just add the new message
      else {
        ConvoService.addMessage(this.convo.convo_id, { message }).catch((e) => {
          console.log(e);
          eventBus.$emit("alert", "Message failed to send");
        });
      }
    },
    // changes the convo's status
    _updateConvoStatus(status) {
      ConvoService.updateConvoStatus(this.convo.convo_id, status).catch((e) => {
        console.log(e);
        eventBus.$emit("alert", "Error changing conversation status");
      });
    },
    leaveConversation() {
      ConvoService.deleteParticipant(this.convo.convo_id).catch((e) => {
        console.log(e);
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (!this.$_scrollingToBottom) {
          this.scrollToPosition(999999999);
          this.$_scrollingToBottom = true;
        }
      });
    },
    scrollToPosition(position) {
      if (!this.$refs.scroller) return;
      const scroller = this.$refs.scroller.$el;
      scroller.scrollTop = position;
      requestAnimationFrame(() => {
        scroller.scrollTop = position;
        setTimeout(() => {
          scroller.scrollTop = position;
          this.$_scrollingToBottom = false;
        }, 100);
      });
    },
  },
};
</script>
