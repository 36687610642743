<template>
  <div style="flex: 1; display: flex; flex-direction: column" class="mx-2">
    <template v-if="convo">
      <div style="flex: 1; align-self: flex-start" class="ml-2">
        <v-tooltip location="top" text="Add emoji (feature in development)">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small">mdi-emoticon-outline</v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Add image (feature in development)">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small">mdi-image-outline</v-icon>
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Add link (feature in development)">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small">mdi-link</v-icon>
          </template>
        </v-tooltip>
      </div>
    </template>

    <div style="flex: 1">
      <v-menu
        :model-value="
          toggleMention && filteredUsers.length > 0 && !disablePopup
        "
        max-width="200"
        scroll-strategy="none"
        location="top"
        :offset="[0, -caretPosition.left]"
      >
        <template v-slot:activator="{ props }">
          <v-textarea
            v-bind="props"
            v-model="message"
            @click:append-inner="messageInput()"
            @click:append="reminderClicked()"
            @keyup="calculateMention"
            @keydown="checkKeyPressed"
            :disabled="(convo && !convo.convo_status) || disableTextarea"
            rows="1"
            append-inner-icon="mdi-send"
            append-icon="mdi-alarm"
            auto-grow
            filled
            rounded
            variant="plain"
            style="margin-left: 10px"
            placeholder="Type message"
            ref="textarea"
            id="vtextarea"
          >
          </v-textarea>
        </template>
        <RecycleScroller
          v-show="toggleMention && filteredUsers.length > 0 && !disablePopup"
          :items="filteredUsers"
          :minItemSize="25"
          :item-size="45"
          key-field="user_id"
          v-slot="{ item }"
          class="text-caption mention"
        >
          <v-hover v-slot="{ hover }">
            <div
              @click="userSelected(item)"
              style="display: flex; align-items: center; height: 45px"
              class="pl-2"
              :class="hover ? 'highlighted' : ''"
            >
              <UserPopup noMenu dense :user_id="item.user_id" style="flex: 1" />
              <div
                style="
                  flex: 4;
                  display: flex;
                  flex-direction: column;
                  height: 45px;
                  justify-content: center;
                "
              >
                <span style="height: 15px; font-size: 13px" class="ml-2">{{
                  item.username
                }}</span>
                <span style="font-size: 9px" class="ml-2">{{
                  item.first_name + " " + item.last_name
                }}</span>
              </div>
            </div>
          </v-hover>
        </RecycleScroller>
      </v-menu>
    </div>
  </div>
</template>

<script>
import UserPopup from "@/components/Avatar/UserPopup";
import { mapGetters } from "vuex";
// Will use this function from textarea-caret package
let getCaretCoordinates = require("textarea-caret");

export default {
  name: "MessengerBox",
  components: {
    UserPopup,
  },
  props: {
    convo: { type: Object, default: null },
  },
  data() {
    return {
      message: "",
      mention: "",
      toggleMention: false,
      // This is how we will identify where to place the @mentions pop-up, using the textarea-caret package
      // we will get the values of the fields 'left' and 'top,' while we will calculate 'topAdjusted'
      // ourselves based on the value we received for 'top.' Then, we will position the v-menu using the 'left'
      // and 'topAdjusted' fields, nudging the v-menu to the right by 'left' pixels and nudging the v-menu
      // to the bottom by 'topAdjusted pixels.'
      caretPosition: { left: 0, top: 0, topAdjusted: 0 },
      currMentionStartIndex: null,
      oldTop: 5,
      edgeCaseAdjustment: 0,
      prevAdjustmentResult: 3,
      textAreaHeight: null,
      mentionLength: -1,
      // This will be an object array that will hold all the mentions,
      // Each object will have the following fields:
      // 2) starting index of mention
      // 3) ending index of mention
      mentions: [],
      // Will hold the index of all the mentions that need to have their starting and
      // ending index update due to the message text either increasing or decreasing in
      // size.
      mentionIndicesToUpdate: [],
      disableTextarea: false, //prolly get rid of this!
      cursorOverMention: false,
    };
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("people", ["users"]),
    // filters the users based on the mention text
    filteredUsers: function () {
      if (!this.users) {
        return [];
      } else {
        let result = this.users.filter((user) => {
          if (user.last_name) {
            const fullName = user.first_name + " " + user.last_name;
            return (
              user.username
                .toLowerCase()
                .startsWith(this.mention.toLowerCase()) ||
              fullName.toLowerCase().startsWith(this.mention.toLowerCase()) ||
              user.last_name
                .toLowerCase()
                .startsWith(this.mention.toLowerCase())
            );
          } else {
            return (
              user.username
                .toLowerCase()
                .startsWith(this.mention.toLowerCase()) ||
              user.first_name
                .toLowerCase()
                .startsWith(this.mention.toLowerCase())
            );
          }
        });

        let mentionSplit = this.mention.split(" ");
        //console.log("cursor index: "+this.cursorIndex());
        //console.log("mention split length: "+mentionSplit[0].length);
        //console.log("currMentionStartIndex: "+this.currMentionStartIndex);
        let mentionIndex =
          parseInt(this.currMentionStartIndex) +
          parseInt(mentionSplit[0].length);
        //console.log("mention index: "+mentionIndex);
        if (result.length > 0 || this.cursorIndex() > mentionIndex) {
          return result;
        } else {
          return this.users.filter((user) => {
            //console.log(mentionSplit);
            if (user.last_name) {
              const fullName = user.first_name + " " + user.last_name;
              return (
                user.username
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                fullName
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                user.last_name
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase())
              );
            } else {
              return (
                user.username
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                user.first_name
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase())
              );
            }
          });
        }
      }
    },
    showVMenu: function () {
      return (
        this.toggleMention &&
        this.filteredUsers.length > 0 &&
        !this.disablePopup
      );
    },
  },
  watch: {
    message: function () {
      if (this.message == "") this.calculateMention();
    },
    showVMenu: function () {
      if (this.showVMenu == false) {
        this.edgeCaseAdjustment = 0;
      }
    },
    mention: function () {
      if (this.filteredUsers.length == 1 && this.toggleMention) {
        let fullName;
        if (this.filteredUsers[0].last_name) {
          fullName =
            this.filteredUsers[0].first_name.toLowerCase() +
            " " +
            this.filteredUsers[0].last_name.toLowerCase();
        } else {
          fullName = null;
        }
        if (
          this.filteredUsers[0].username.toLowerCase() ==
            this.mention.toLowerCase() ||
          this.filteredUsers[0].first_name.toLowerCase() ==
            this.mention.toLowerCase() ||
          fullName == this.mention.toLowerCase()
        ) {
          this.userSelected(this.filteredUsers[0]);
        }
      }
    },
  },
  methods: {
    messageInput() {
      if (this.message !== "") {
        this.$emit("messageSent", this.message);
        this.message = "";
      }
    },
    async userSelected(user) {
      let messageFirstHalf = this.message.slice(0, this.currMentionStartIndex);
      let mentionSplit = this.mention.split(" ");
      let messageSecondHalf;
      //console.log("in user selected: "+this.mention);
      if (mentionSplit.length > 1 && mentionSplit[1].length > 0) {
        if (
          this.filteredUsers.filter((e) => {
            if (e.last_name) {
              let fullName =
                e.first_name.toLowerCase() + " " + e.last_name.toLowerCase();
              return fullName.startsWith(this.mention.toLowerCase());
            } else {
              return false;
            }
          }).length > 0
        ) {
          messageSecondHalf = this.message.slice(
            this.currMentionStartIndex + this.mention.length
          );
        } else {
          messageSecondHalf = this.message.slice(
            this.currMentionStartIndex + mentionSplit[0].length
          );
        }
      } else {
        messageSecondHalf = this.message.slice(
          this.currMentionStartIndex + mentionSplit[0].length
        );
      }

      // Adding 6 spaces here to the message to account for the extended mention ui length
      // and 1 space extra so user doesn't have to hit spacebar.
      // Sike it's just 5 spaces actually.
      // Update: Ending up just adding 1 extra space to do the additional automatic spacebar after a mention
      // for the user.
      this.message = messageFirstHalf + user.username + " " + messageSecondHalf;

      // Getting starting and ending indices of mention
      let startIndex = parseInt(messageFirstHalf.length) - 1;
      let endIndex = startIndex + parseInt(user.username.length) + 1;
      console.log("startindex: " + startIndex);
      console.log("endindex: " + endIndex);

      // Toggle mention to false as we do not need the mention popup anymore
      this.toggleMention = false;

      // Creating new mention object
      let newMention = {
        start: startIndex,
        end: endIndex,
      };

      // Adding mention to our mentions object array
      this.mentions.push(newMention);

      // Here we are temporarily disabling the popup after the mention was created
      // so that if the person's username is also the first name of the individual
      // who was just mentioned, the pop up will not keep showing after they hit the
      // spacebar (since our program will then assume they are trying to still
      // pick the person to mention by typing in their last name, when in reality
      // the user had already picked the mention).
      this.disablePopup = true;
    },
    reminderClicked() {
      this.$emit("reminderClicked");
    },
    checkKeyPressed(event) {
      if (event && event.key == "@") {
        // If the key pressed was an @ symbol we can re-enable to popup to show.
        this.disablePopup = false;
      }
    },
    calculateMention(event) {
      let b = [
        ...this.message.matchAll(/\B(@([a-z0-9_-]+)( ([a-z0-9_-]+))?)|(@)/gi),
      ];
      // An array of entries for each mention in the text, where each entry is itself
      // an array of size 2, the first element indicating the starting cursor index of that
      // mention and the second element indicating the ending cursor index of that mention.
      let mentionIndices = b.map((a) => [a.index + 1, a.index + a[0].length]);
      //console.log(mentionIndices);

      if (mentionIndices.length == 0) return;

      let cursorIndex = event.target.selectionEnd;
      console.log('cursorindex', cursorIndex)
      let caret = getCaretCoordinates(event.target, cursorIndex);

      let currTextAreaHeight = event.target.style?.height || 32;

      //console.log("Curr cursor index: " + this.cursorIndex());

      // getting the current mention if the user's cursor is over a portion of text that
      // requires a mention/is an unfinished mention (will hold start index of the mention
      // as the first element, and end index as the second element, currMention is a
      // two-element array).
      const currMention = mentionIndices.findIndex(
        (a) => cursorIndex >= a[0] && cursorIndex <= a[1]
      );

      //console.log("Curr mention return val: " + currMention);

      if (currMention >= 0) {
        this.currMentionStartIndex = mentionIndices[currMention][0]; //added this!
        this.mention = this.message.substring(
          mentionIndices[currMention][0],
          mentionIndices[currMention][1]
        );

        let atSymbol = getCaretCoordinates(
          event.target,
          mentionIndices[currMention][0] - 1
        );

        this.caretPosition = atSymbol;

        // edge case stuff
        if (this.textAreaHeight && this.textAreaHeight < currTextAreaHeight) {
          if (this.caretPosition.top > 33) {
            this.edgeCaseAdjustment = 25;
          } else {
            this.edgeCaseAdjustment = 10;
          }
          console.log("down");
        } else if (
          this.textAreaHeight &&
          this.textAreaHeight > currTextAreaHeight
        ) {
          this.edgeCaseAdjustment = 0;
          console.log("up");
        }

        // The 45 here is the height of each item in the RecycleScroller (the value of :itemSize prop)
        if (this.filteredUsers.length * 45 < 200) {
          this.caretPosition.topAdjusted =
            this.caretPosition.top -
            this.filteredUsers.length * 45 -
            10 -
            this.edgeCaseAdjustment; // Minus 10 for buffer, so pop-up not right on top of words.
        } else {
          this.caretPosition.topAdjusted = this.caretPosition.top - 200;
        }
        this.toggleMention = true;
      } else {
        this.toggleMention = false; // User cursor not over a mention
      }

      this.textAreaHeight = currTextAreaHeight;
      this.oldTop = caret.top;

      return mentionIndices;
    },
    // gets the cursor index in the v-textarea
    cursorIndex() {
      try {
        let index =
          this.$refs.textarea.$el.$el.children[0].children[0].children[2]
            .children[1].selectionStart;

        return index;
      } catch (e) {
        return 0;
      }
    },
  },
};
</script>

<style scoped>
.mention {
  width: 200px;
  max-height: 200px;
  background: white;
  border: 5px solid #f0f0f0;
  text-align: left;
}
</style>
