<template>
  <v-card>
    <!-- complete button -->
    <v-tooltip location="top" text="Complete task">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          v-if="
            message.convo_mentions.length > 0 &&
              message.convo_mentions.some((m) => m.user_id === userId && !m.completed)
          "   
          icon
          variant="text"
          style="width: 25px;height: 25px;"
          @click="completeTask()"
        >
          <v-icon size="small">mdi-check-outline</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- reaction button -->
    <v-tooltip location="top" text="Add reaction (feature in development)">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon>
          <v-icon size="small">mdi-emoticon</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- save button -->
    <v-tooltip location="top" text="Save message (feature in development)">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon>
          <v-icon size="small">mdi-content-save</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
  </v-card>
</template>

<script>
import ConvoSerice from "@/services/convoService";
import { mapGetters } from "vuex";
import eventBus from "@/eventBus";

export default {
  name: "MessagePopup",
  props: {
    message: { type: Object, default: null },
  },
  computed: {
    ...mapGetters(["userId"]),
  },
  methods: {
    completeTask() {
      ConvoSerice.updateMentionStatus(this.message.convo_id, this.message.convo_message_id, { completed: true }).catch(
        (e) => {
          eventBus.$emit("alert", "Error completing task");
          console.log(e);
        }
      );
    },
  },
};
</script>
