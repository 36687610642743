<template>
  <div class="container" ref="container">
    <div class="left-right-container">
      <LeftPanel class="left-panel" :style="{ flex: leftFlex }" />
      <div class="divider" @mousedown="startDraggingLeft"></div>
      <CenterPanel class="center-panel" :style="{ flex: centerFlex }"></CenterPanel>
      <div style="width: 1px;background-color: black;"></div>
      <!-- <RightPanel class="right-panel" :style="{ flex: rightFlex }"></RightPanel> -->
      <FrontDeskRightPanel class="right-panel" :style="{ flex: rightFlex }"></FrontDeskRightPanel>
    </div>
  </div>
</template>

<script>
import LeftPanel from "@/components/FrontDesk/LeftPanel";
import CenterPanel from "@/components/FrontDesk/CenterPanel";
import RightPanel from "@/components/FrontDesk/RightPanel/RightPanel";
import FrontDeskRightPanel from "./FrontDeskRightPanel"

import { mapActions,mapState } from "vuex";

export default {
  name: "FrontDesk",
  data() {
    return {
      isDraggingLeft: false,
      isDraggingRight: false,
      leftFlex: 2.2,
      centerFlex: 9,
      rightFlex: 0.5,
      startX: 0,
    };
  },
  components: {
    LeftPanel,
    CenterPanel,
    RightPanel,
    FrontDeskRightPanel
  },
  computed:{
    ...mapState(['isPanelVisible']),
    rightFlex() {
      return this.isPanelVisible ? 6 : 0.5;
    }
  },
  methods: {
    adjustCenterFlex() {
      const totalFlex = this.leftFlex + this.centerFlex + this.rightFlex;
      this.centerFlex = totalFlex - this.leftFlex - this.rightFlex;
    },
    ...mapActions("frontdesk", ["retrieveCalls"]),
    startDraggingLeft(event) {
      this.isDraggingLeft = true;
      this.startX = event.clientX;
      document.addEventListener('mousemove', this.onDragLeft);
      document.addEventListener('mouseup', this.stopDragging);
    },
    onDragLeft(event) {
      if (!this.isDraggingLeft) return;
      const deltaX = event.clientX - this.startX;
      const totalFlex = this.leftFlex + this.centerFlex + this.rightFlex;

      const containerWidth = this.$refs.container.offsetWidth;
      const deltaFlex = (deltaX / containerWidth) * totalFlex;

      this.leftFlex = Math.max(1, Math.min(totalFlex - 2, this.leftFlex + deltaFlex));
      this.adjustCenterFlex();

      this.startX = event.clientX;
    },
    stopDragging() {
      this.isDraggingLeft = false;
      this.isDraggingRight = false;
      document.removeEventListener('mousemove', this.onDragLeft);
      document.removeEventListener('mousemove', this.onDragRight);
      document.removeEventListener('mouseup', this.stopDragging);
    },
    
  },
  mounted() {
    this.retrieveCalls().catch((e) => {
      console.log(e);
    });
    
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100%;
  overflow-y: hidden;
}
.left-right-container {
  display: flex;
  height: 100%;
  width: 100%;
}
.left-panel {
  flex: 10;
}
.center-panel {
  flex: 12;
}
.right-panel {
  flex: 6;
}
.divider {
  width: 2px;
  background-color: #000;
  cursor: ew-resize;
}
.divider:hover{
  background-color: blue;
  width: 5px;
}
</style>
