<template>
  <div>
    <!-- display the date if necessary -->
    <div v-if="showDate()" class="text-caption separator mt-2">
      <div class="date mb-1">
        {{ simplifyDate(message.date, "LL") }}
      </div>
    </div>
    <!-- display the message -->
    <div :class="'chat-bubble-container ' + (showDate() ? 'mb-2' : '')">
      <template v-if="message.who_sent !== 0">
        <v-menu offset-y top open-on-hover :close-on-content-click="false" close-delay="160" max-width="100px">
          <template v-slot:activator="{ props }">
            <div v-if="message.who_sent !== userId" style="margin-left: 0.3rem;">
              <UserPopup :user_id="message.who_sent" />
            </div>
            <div v-bind="props"
              :class="message.who_sent === userId ? 'chat-bubble msg-self' : 'chat-bubble msg-friend'">
              <div v-if="message.convo_mentions.length === 0">{{ message.message }}</div>
              <div v-else v-for="item in splitbyMentions(message)" :key="item.index" style="display: inline">
                <div v-if="item.user_id" style="display: inline">
                  <UserText :text="item.text" :user_id="item.user_id" :color="item.completed ? 'green' : 'red'" />
                </div>
                <template v-else>{{ item.text }}</template>
              </div>
              <div v-if="hasAttachments()">
                <div v-for="(value, index) in message.convo_attachments" :key="index">
                  <div v-if="resolvedUrls[value.media_id]">
                    <template v-if="resolvedUrls[value.media_id].contentType == 'img'">
                      <v-img v-on:click="openImg(resolvedUrls[value.media_id].url)" max-height="120" max-width="200"
                        :src="resolvedUrls[value.media_id].url"></v-img>
                    </template>
                    <template v-if="resolvedUrls[value.media_id].contentType == 'pdf'">
                      <a v-bind:href="'/api/convos/internal/messages/' + message.convo_message_id + '/media/' + value.media_id"
                        target="_blank">
                        <v-icon>
                        mdi-file-pdf-box
                        </v-icon>
                      </a>
                    </template>
                  </div>
                </div>
              </div>
              <!-- display the message time -->
              <div class="time">
                {{ simplifyDate(message.date, "LT") }}
              </div>
            </div>
          </template>
          <MessagePopup :message="message" />
        </v-menu>
      </template>
      <template v-else>
        <div class="text-caption systemMessage">
          {{ message.message + " (" + simplifyDate(message.date, "LT") + ")" }}
        </div>
      </template>
    </div>
    <!-- insert space between each message -->
    <div style="font-size: 0.2rem;">
      <br />
    </div>
  </div>
</template>

<script>
import UserPopup from "@/components/Avatar/UserPopup";
import UserText from "@/components/Avatar/UserText";
import MessagePopup from "@/components/Messenger/MessagePopup";
import ConvoSerice from "@/services/convoService";
import { mapGetters } from "vuex";

export default {
  name: "Message",
  components: {
    UserPopup,
    UserText,
    MessagePopup,
  },
  props: {
    message: { type: Object, default: null },
    prev: { type: Object, default: null },
    index: { type: Number, default: null },
    active: { type: Boolean, default: true },
  },
  data() {
    return {
      resolvedUrls: {}
    };
  },
  computed: {
    ...mapGetters(["userId"]),
  },
  mounted() {
    if(this.message.convo_attachments){
      this.message.convo_attachments.forEach(attachment => {
      this.fetchAndStoreUrl(this.message.convo_message_id, attachment.media_id);
    });
    }
  },
  methods: {
    //Get the call Multimedia file URL
    async fetchAndStoreUrl(convo_message_id, media_id) {
      let res = await ConvoSerice.getConvoMediaUrl(convo_message_id, media_id)
      console.log(res)
      const response = await fetch(res, { method: 'HEAD' });
      const contentType = response.headers.get('content-type');
      console.log(contentType)
      this.$data.resolvedUrls[media_id] = {
        url: res,
        contentType: 'img'
      };
      if (contentType && contentType.includes('pdf')) {
        this.$data.resolvedUrls[media_id].contentType = 'pdf'
      }

    },
    openImg(url) {
      window.open(url);
    },
    // async getFileUrl(convo_message_id, media_id) {
    //   let res = await ConvoSerice.getConvoMediaUrl(convo_message_id, media_id)
    //   return res;
    // },
    showDate() {
      return this.active && (this.index == 0 || this.simplifyDate(this.message.date, 'LL') != this.simplifyDate(this.prev.date, 'LL'));
    },
    hasAttachments() {
      return this.message.convo_attachments && this.message.convo_attachments.length > 0;
    },
    // the possible formats can be found at momentjs.com
    simplifyDate(date, format) {
      return this.moment(date).format(format);
    },
    // returns true if the two dates are within n minutes
    withinMinutes(date1, date2, n) {
      return this.moment(date1).diff(this.moment(date2), "minutes") <= n;
    },
    // splits the message by mentions
    splitbyMentions(message) {
      let splitIndices = [];
      message.convo_mentions.forEach((m) => {
        splitIndices.push(m.index_start);
        splitIndices.push(m.index_end);
      });
      let acc = -1;
      return [0, ...splitIndices].map((n, i, m) => {
        return {
          text: message.message.slice(n, m[i + 1]),
          user_id: i % 2 !== 0 ? message.convo_mentions[(acc += 1)].user_id : null,
          index: i,
          completed: i % 2 !== 0 ? message.convo_mentions[acc].completed : null,
        };
      });
    },
  },
};
</script>

<style scoped>
.chat-bubble-container {
  white-space: pre-wrap;
  display: flex;
}

.chat-bubble {
  text-align: left;
  font-size: 0.9rem;
  padding: 0.4rem 0.5rem;
  border-radius: 0.5rem;
  width: auto;
  max-width: 80%;
}

.msg-friend {
  background-color: #e3e3e3;
  margin-left: 0.3rem;
  margin-right: auto;
}

.msg-self {
  background-color: #d9f7dc;
  align-items: flex-end;
  align-content: flex-end;
  align-self: flex-end;
  margin-right: 0.3rem;
  margin-left: auto;
}

.systemMessage {
  flex: 1;
  color: #6e6e6e;
}

.separator {
  display: flex;
  align-items: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #e0e0e0;
}

.date {
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
}

.time {
  font-size: 0.6rem;
  text-align: right;
  color: #90a09f;
}
</style>
