import Vue from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store"

// import all routes
import Login from "../views/Login.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PasswordUpdate from "../views/PasswordUpdate.vue";
import AccountActivation from "../views/AccountActivation.vue";
import ClientList from "../views/ClientList.vue";
import ClientPhonesList from "../views/ClientPhonesList.vue";
import ClientEndpoints from "../views/ClientEndpoints.vue";
import Home from "../views/Home.vue";
import FrontDesk from "../views/FrontDesk.vue";
import OSClaims from "../views/OSClaims.vue";
import Recall from "../views/Recall.vue";
import VPI from "../views/VPI.vue";
import AuthService from "@/services/authService";


const routes = [
  {
    path: "/",
    name: "Log In",
    alias: "/login",
    component: Login,
  },
  {
    path: "/password/reset",
    name: "Password Reset",
    component: PasswordReset,
  },
  {
    path: "/password/update/:userId/:token",
    name: "Password Update",
    component: PasswordUpdate,
  },
  {
    path: "/account/activate/:name/:token",
    name: "Account Activation",
    component: AccountActivation,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/frontdesk",
    name: "Front Desk Workflow",
    component: FrontDesk,
  },
  {
    path: "/clientlist",
    name: "Client List",
    component: ClientList
  },
  {
    path: "/clientphones/:id",
    name: "Client Phones List",
    component: ClientPhonesList,
  },
  {
    path: "/clientendpoints/:id",
    name: "Client Endpoints List",
    component: ClientEndpoints,
  },
  {
    path: "/osclaims",
    name: "Outstanding Claims Workflow",
    component: OSClaims,
  },
  {
    path: "/recall",
    name: "Recall Workflow",
    component: Recall,
  },
  {
    path: "/vpi",
    name: "VPI",
    component: VPI,
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory()
});

// blocks all application routes if the user is not authenticated
router.beforeEach(async(to, from, next) => {
  console.log('Inside beforeEach', store.state)
  console.log(`Navigating from ${from.path} to ${to.path}`);
  // If the user is navigating to a protected route,
  // check if they are authenticated by calling AuthService.check()
  
    const check = await AuthService.check();
    const user_id = check["data"]["valid"]["user_id"];
    console.log("check from index :" + JSON.stringify(check) + " and value is:" + user_id);
    if(user_id!=undefined && !store.state.validUser ){
            store.commit("user", check.data.valid);
            store.commit("loggedIn", true);
            store.commit("clientName", check.data.valid.client.name);
            store.commit("clientId", check["data"]["valid"].client_id);
    }
    // If the user is not authenticated, redirect to the login page
    if (!user_id && to.path !== "/") {
      console.log(`User is not authenticated, redirecting to ${to.path}`);
      next({ path: "/"});
    }else if(to.path== "/" && user_id!=undefined){
      console.log(`User is authenticated, navigating to frontdesk `);
      next({ path: "frontdesk"});
    }
    // If the user is authenticated, allow them to access the route
    else {
      console.log(`User is authenticated, navigating to ${to.path}`);
      next();
    }
  
  
  /*
  if (to.path !== "/" && !store.state.validUser ) {
    const check = await AuthService.check();
    let user_id = check["data"]["valid"]["user_id"];
    console.log("check from index :" + JSON.stringify(check) + " and value is:" + user_id);
    console.log(`Redirecting to root path "/"`);
    if (user_id == undefined) {
      next({ path: "/" });
    } else {
      console.log("Navigating to next route 1");
      next();
    }
  } else if(to.path== "/"){
    const check = await AuthService.check();
    let user_id = check["data"]["valid"]["user_id"];
    console.log("check from index :" + JSON.stringify(check) + " and value is:" + user_id);
    console.log(`Redirecting to root path "/"`);
    if (user_id != undefined) {
      next({ path: "frontdesk" });
    } else {
      console.log("Navigating to next route 1");
      next();
    }
  }else{
    console.log("Navigating to next route");
    next();
  }*/
});


export default router;
